import { connect } from 'react-redux'
import { selectLocationIds } from 'reports/planningProducts/selectors'
import PlanningProductsReportLocationToggle from 'reports/planningProducts/PlanningProductsReportLocationToggle'
import ProductionReportLocations from 'components/ProductionReportLocations'
import PlanningProductsLocationSelectNone from './PlanningProductsLocationSelectNone'
import PlanningProductsLocationSelectAll from './PlanningProductsLocationSelectAll'

const mapStateToProps = (state) => ({
  ids: selectLocationIds(state),
  Toggle: PlanningProductsReportLocationToggle,
  SelectAll: PlanningProductsLocationSelectAll,
  SelectNone: PlanningProductsLocationSelectNone
})

export default connect(mapStateToProps)(ProductionReportLocations)
