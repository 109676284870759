import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import DatePicker from 'components/DatePicker'
import { selectVisibleLocationIds } from './selectors'
import { DATE_FORMAT } from 'lib/const'
import Input from 'components/Input'
import Button from 'components/Button'

const maxDaysPeriodLength = 7 * 5 // 5 weeks

class PlanningProductsReportForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = { startDate: null, endDate: null }
  }

  handleDateChange (change) {
    this.setState(change)
  }

  render() {
    const emptyDates = !(this.state.startDate && this.state.endDate)
    const periodTooLong = !emptyDates && this.state.endDate.diff(this.state.startDate, 'days') > maxDaysPeriodLength

    const params = {
      startDate: this.state.startDate && this.state.startDate.toISOString(),
      endDate: this.state.endDate && this.state.endDate.toISOString(),
      locationIds: JSON.stringify(this.props.ids),
      format: 'xlsx'
    }

    const canDownload = !(emptyDates ||
                          periodTooLong ||
                          this.props.ids.length === 0 ||
                          this.state.startDate > this.state.endDate)

    return (
      <form>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0', gap: '20px' }}>
          <Input
            nested
            label={this.props.intl.formatMessage({ id: 'from' })}
            style={{ width: '100px' }}
          >
            <DatePicker
              selected={this.state.startDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={value => this.handleDateChange({ startDate: value })}
              selectsStart
              dateFormat={DATE_FORMAT}
            />
          </Input>
          <Input
            nested
            label={this.props.intl.formatMessage({ id: 'to' })}
            style={{ width: '100px' }}
          >
            <DatePicker
              selected={this.state.endDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={value => this.handleDateChange({ endDate: value })}
              selectsEnd
              dateFormat={DATE_FORMAT}
            />
          </Input>
        </div>
        <div style={{ textAlign: 'center' }}>
          <a
            href={
              canDownload
                ? '/reports/planning_products.xlsx?' + new URLSearchParams(params).toString()
                : '#'
            }
            format='xlsx'
            target='_blank'
            style={{ pointerEvents: canDownload ? '' : 'none' }}
          >
            <Button
              color='success'
              disabled={!canDownload}
              tag='div'
            >
              XLSX
            </Button>
          </a>
        </div>
        {
          periodTooLong &&
          (
            <div style={{ textAlign: 'center', margin: '10px 0 0', color: 'red' }}>
              <FormattedMessage
                id='reports.planningProducts.tooLongPeriod'
                defaultMessage='Given period is too long (max. {days} days).'
                values={{ days: maxDaysPeriodLength }}
              />
            </div>
          )
        }
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  ids: selectVisibleLocationIds(state),
})

export default compose(connect(mapStateToProps), injectIntl)(PlanningProductsReportForm)
